$color-main-bg: #303030;
// $color-link-text:
#topbar-header {
  background-color: $color-main-bg;
}
.main-header {
  background-color: $color-main-bg;
}
.navbar {
  padding: 12px 12px;
}

.nav-item a {
  all: unset;
  cursor: pointer;
  transition-duration: 0.3s;
}
.help-ul {
  position: relative;
}
.help-ul a {
  margin-right: 15px;
  &::after {
    content: "";
    background: #fff;
    width: 0px;
    height: 2px;
    display: block;
    transition: width 0.3s;
    position: absolute;
  }
  &:hover {
    &::after {
      width: 40px;
    }
  }
  a {
    color: #fff;
    &:hover {
      color: #bdbdbd;
      text-shadow: 0px 0px 1px #dcdcdc;
    }
  }
}

.contact_search {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  li {
    a {
      color: white;
    }
  }
}

ul.contact_search li a.contact-us {
  padding: 7px 15px;
  background: #3e8993;
  border: 1px solid #3e8993;
  color: #fff;
  font-weight: 500;
  border-radius: 5px;
  font-size: 16px;
}

header {
  padding: 10px 0 10px 0;
  background: #303030;
  position: relative;
  width: 100%;
  z-index: 9999;
  transition: all 0.4s ease;
  top: 0;
  left: 0;
  right: 0;
}
