.check-table-cont {
  border: 0.1px solid #dadada;
  max-width: 730px;
  overflow-x: auto;
}

.check-table-cont table {
  text-indent: initial;
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
}

.check-table-cont table th {
  color: #000;
  padding: 8px 30px;
  text-align: inherit;
  display: table-cell;
  vertical-align: inherit;
  font-style: "Montserrat", sans-serif;
}

.check-table-cont table th:first-child {
  width: 40%;
  white-space: nowrap;
  text-align: center;
}

.check-table-cont table th:nth-child(2) {
  width: 40%;
  white-space: nowrap;
  text-align: center;
}

.check-table-cont table tr {
  display: table-row;
  vertical-align: middle;
  border-color: inherit;
  margin: 10px 15px;
}

.check-table-cont table td:first-child {
  white-space: nowrap;
  text-align: start;
}

.check-table-cont table td:nth-child(2) {
  white-space: nowrap;
  text-align: start;
  color: #000;
  padding: 0px 15px;
  font-size: 14px !important;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  display: table-cell;
  vertical-align: middle !important;
  text-align: center;
}

.check-table-cont table td .form-group {
  margin: 0;
  padding: 0;
}

.check-table-cont table td .check_custom {
  margin: 0;
  padding: 0;
}

.check-table-cont table td .check_custom label {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5f5f5f;
  cursor: default;
}

.check-table-cont table td .check_custom label .check_box {
  vertical-align: middle;
  cursor: pointer;
}

.check-table-cont table input[type="checkbox"] {
  display: none;
}

.check-table-cont table .check_box {
  height: 14px;
  width: 14px;
  background-color: #878787;
}

.check-table-cont table input:checked ~ .check_box {
  background-color: #3e8993;
}

.check-table-cont table li::before {
  content: "•";
  color: blue;
}

.table-btm-content {
  max-width: 730px;
}

.table-btm-content .avalaible-sign {
  width: 10px;
  height: 10px;
  background-color: #3e8993;
  margin-right: 10px;
}

.table-btm-content .not-avalaible-sign {
  width: 10px;
  height: 10px;
  background-color: #878787;
  margin-right: 10px;
}

.table-btm-content .select-deselect-all {
  margin-right: 10px;
  background-color: #878787;
}

.table-btm-content label {
  font-size: 15px;
  font-weight: 500;
}

.sch-sub-heading {
  color: #000000;
  font-weight: 500;
}

.pt-custom {
  padding-top: 1.25rem !important;
}

.profile-img-view {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50% !important;
}
