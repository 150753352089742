/*
    DEMO STYLE
*/
/*@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";*/
:root{
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      } 
    /* Track */
    ::-webkit-scrollbar-track {
    background: white;
    box-shadow: inset 0 0 5px grey;
    
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #3e8993;
    border-radius: 24px;
    }
}
body {
    font-family: 'Montserrat"', sans-serif;
    background: #fafafa;
}


p {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #303030;
    color: #fff;
    transition: all 0.3s;
}

#sidebar.active {
    margin-left: -250px;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #303030;
}

#sidebar ul.components {
    padding: 20px 0;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}

/*#sidebar ul li a:hover {
    color: #7386D5;
    background: #fff;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
    background: #6d7fcc;
}*/

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    /*background: #6d7fcc;*/
    color: #fff;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article,
a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    /*padding: 20px;*/
    min-height: 100vh;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
    }
    #sidebarCollapse span {
        display: none;
    }
}

.btn-link {
    font-weight: 400;
    color: white;
    text-decoration: none;
}

.modal-close {
    position: absolute;
    top: 17px;
      right: -1px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    width: 35px;
    height: 35px;
  
    transform: translateY(-50%);
    color: #3e8993;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
    background-color: #3e8993;
    border: 1px solid;
    padding-right: 10px;
    padding-left: 10px;
  }

.gallery-modal-close{
    opacity: 0.7;
    position: absolute;
    top: 17px;
      right: -1px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    width: 35px;
    height: 35px;
  
    transform: translateY(-50%);
    color: #3e8993;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
    background-color: #3e8993;
    border: 1px solid;
    padding-right: 10px;
    padding-left: 10px;
  }

  .modal-close-img {
    height:21px !important;
    max-width: 200% !important;
  }

  .image-modal {
    width: 80%; /* Adjust the width as per your preference */
    height: 80%; /* Adjust the height as per your preference */
    max-width: 800px; /* Maximum width of the modal */
    max-height: 600px; /* Maximum height of the modal */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
    padding: 2rem;
}

.custom-confirmation-modaal {
    width: 80%; 
    height: 80%; 
    max-width: 800px; 
    max-height: 600px; 
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .loading-spinner {
    animation: spin 1s infinite linear;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.active-menu{
    background-color: #3e8993;
}

.not-active-submenu{
    background-color: #3C3A3C !important;
}

.overflow-x-hidden{
    overflow-x: hidden !important;
}

.jzrolw {
    overflow-y: auto !important;
    max-height: 64vh !important;
    background-color: white !important;
    /* min-height: 64vh !important; */
}

.no-scroll {
    overflow-y: hidden !important;
    max-height: none !important;
    background-color: transparent !important;
  }

/* Custom CSS to make the header sticky */
.sticky-header-class {
    position: relative;
  }
  
  .sticky-header-class .rdt_TableHead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
  }
  
  ::-webkit-scrollbar {
   /* height: 7px !important; */
}

.eguvnT, .jzrolw {
    overflow-y: auto !important;
    max-height: 64vh !important;
    background-color: white !important;
    /* min-height: 64vh !important; */
}

.spinner {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1001;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
}

.spinner div svg {
    width: 80px;
    height: 80px;
    margin-bottom: 20px; 
    /*margin-left: -200px*/
}

.flex-zero {
    flex: 0 !important;
}