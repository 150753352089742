.disabled-btn {
  border-radius: 5px !important;
  pointer-events: none !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.form-back-color {
  background: #eaeaea !important;
}

.h-150 {
  height: 150px !important;
}

.btn-pos {
  padding: 8px 25px !important;
  margin-top: 1.8rem !important;
}

.custom-p-color {
  color: #212529 !important;
}

.btn-padding {
  padding: 8px 25px !important;
}

/* .left-btn {
  border: 1px solid !important;
  left: -20px !important;
}

.right-btn {
  border: 1px solid !important;
  right: -20px !important;
} */

.left-icon-image,.right-icon-image{
  height: 23px;
}

.footer-color {
  color: #5f5f5f !important;
}

.mt-230 {
  margin-top: 230px !important;
}

.m--auto {
  margin: auto !important;
}

.custom-toggle-label {
  font-family: "Montserrat", sans-serif;
  font-size: 1.1em !important;
  font-weight: 300 !important;
  line-height: 1.7em !important;
  color: #999 !important;
}

.z-index3{
  z-index: 2 !important;
}

.z-index5{
  z-index: 5 !important;
}

.css-1nmdiq5-menu{
  z-index: 3 !important;
}

.ReactModal__Overlay {
z-index: 2;
}

.p-lr-22{
  padding-left: 22px;
  padding-right: 22px;
}

.p-lr-8{
  padding-left: 8px;
  padding-right: 8px;
}

.react-datepicker-wrapper .rmdp-container{
  width: 100% !important;
}

.required-class{
  color: red;
}

.react-datepicker-wrapper .rmdp-input{
  border: 1px solid #ced4da !important;
}

.invalid .rmdp-input{
  border: 1px solid red !important;
}