.message-board-modal {
 border: 1px solid $color-btn;
  border-radius: 5px;
  margin: auto;
//   padding-top: 20px;
//   padding-bottom: 50px;
  padding : 20px;
  overflow: auto;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 75vw;
  max-width: 90vw;
  min-height: 50vh;
  max-height: 90vh;
  overflow: visible;
  h5 {
    text-align: center;
  }
  .main-con {
   min-width: 75vw;
    min-height: 50vh;
    max-height: 80vh;
    overflow: auto;
  }
  .close {
    color: black;
    opacity: 1;
    background-color: white;
    border: 1px solid $color-btn;
    padding: 6px 10px;
    border-radius: 50%;
    font-size: 16px;
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
  }
}

.message-board {
  background-color: #fff;
  box-shadow: 1px 0 10px grey;
  height: 73vh;
  border-radius: 10px;

  .messaged-board-height {
    height: 73vh;
    overflow-y: auto;
  }
  .receiver-messages {
    width: fit-content;
    max-width: 100%;
    padding: 8px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    word-wrap: break-word;
  }
  .sender-messages {
    width: fit-content;
    max-width: 100%;
    padding: 8px;
    border-radius: 10px;
    background-color: #3e8993;
    color: #fff;
    box-shadow: 0 0 0.5em rgba(76, 175, 80, 0.5);
    word-wrap: break-word;
  }
  .h-46 {
    height: 46px;
  }
  .sending-section{
    margin-top: 3%; 
    height: 10vh; 
  }

  .container-vh{
    height: 90vh;
  }
  .font-12{
    font-size: 12px;
  }
}