.upload-gallery {
  font-family: $sans-serif;
  .cursor-pointer {
    cursor: pointer;
  }
  // maintain gallery controls sticky position and layer structure of images, controls and modal.
  .file-buttons {
   position: sticky !important;
  top: 0 !important;
  background-color: #f8f8f8;
  z-index: 10 !important;
  }
  .file-preview {
    z-index: 5 !important;
    max-height: 65vh !important;
    overflow-y: auto !important;
  }
}
.ReactModal__Overlay {
  z-index: 999;
}

.member-my-events-tabs {
  button[aria-selected="true"] {
    padding: 0px 25px;
    font-family: $sans-serif;
    color: black;
    font-weight: 600;
    background-color: white;
    border-top: 2px solid $color-btn;
  }
  button[aria-selected="false"] {
    padding: 0px 25px;
    font-family: $sans-serif;
    color: #9c9c9c;
    font-weight: 600;
    background-color: #d8d8d8;
    // border-top: 1px solid $color-btn;
  }
}

.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled{
  display: none;
}

.css-1q0ig8p{
display: none !important;
}