.drag-drop-table-class {
  top: 0;
  z-index: 3;
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 500;
  align-items: stretch;
  min-height: 52px;
  border-bottom-width: 1px !important;
  border-bottom-color: rgba(0, 0, 0, 0.12) !important;
  border-bottom-style: solid !important;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  max-height: 45vh !important;
  overflow: auto;
}

.table-tr-style {
  cursor: grab;
  border-bottom-width: 1px !important;
  border-bottom-color: rgba(0, 0, 0, 0.12) !important;
  border-bottom-style: solid !important;
  height: 48px !important;
  min-width: 1060px !important;
}

.drag-drop-table-head {
  top: 0;
  z-index: 1;
  background-color: white;
  display: flex;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 500;
  align-items: stretch;
  background-color: #ffffff;
  min-height: 52px;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  border-bottom-style: solid;
  position: sticky;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
  color: rgba(0, 0, 0, 0.87);
  background-color: #ffffff;
  overflow: hidden;
}

.table-tr-style:hover {
  background-color: #eeefee !important;
}

.table-tr-style:hover td {
  background-color: #eeefee !important;
}

.td-style {
  min-width: 50px;
  background-color: white;
  display: flex;
  height: 45px;
  align-items: center;
}

.td-device-style {
  min-width: 400px;
  background: white;
  padding-left: 16px;
  padding-right: 16px;
  height: 45px;
  display: flex;
  align-items: center;
}

.td-btn-style {
  min-width: 610px;
  background-color: white;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: center;
  line-height: normal;
  height: 45px;
  align-items: center;
}

.drag-drop-head-cell {
    padding-left: 16px;
    padding-right: 16px;
    background: white;
    line-height: normal;
}
