.rtl-support {
  .phone-input {
    direction: ltr;
    text-align: right;
  }
}

.input-without-border-radius {
  border-radius: unset;
  width: 100%;
}
