// toast styles overriding, toast size fixed.
.Toastify__toast-container {
  opacity: 0.9;
  font-family: $sans-serif;
  font-size: 14px;
  max-width: 300px;
  transition-duration: 0.3s;
  margin: auto;
  &:hover {
    opacity: 1;
  }
  .Toastify__toast {
    box-shadow: 1.5px 1.5px 12px $color-btn, -1.5px -1.5px 12px $color-btn;
    font-family: $sans-serif;
    border-radius: 3px;
    min-height: 54px;
  }
  // success button unique styles
  .Toastify__toast--success {
    background-color: $color-btn;
  }
  // error button unique styles
  // .Toastify__toast--error{
  // }
  // removes the x icon from toast
  .Toastify__close-button {
    display: none;
    // uncomment following if you change close icon display
    // width: 20px;
    // height: 20px;
  }
}
