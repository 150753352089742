.pagination-card-width{
    width: 95%;
}
.pagination-con{
    .pagination-search-btn{
        border: none;
        transition-duration: 0.3s;
        &:hover{
            cursor: pointer;
            opacity: 0.8;
        }
    }
}