.card-style {
  border: none;
  box-shadow: 0px 0px 10px #ccc;
}

.back-color {
  background-color: white;
}

.a-style {
  color: #5f5f5f;
  font-weight: 500;
}

.fw-bold {
  font-weight: bold;
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #5f5f5f;
  border-color: #5f5f5f;
  pointer-events: none;
}