.bold-header {
  font-weight: bold;
}

.payment-button {
  border-radius: 4px;
  border: 1px solid grey;
  padding: 0.5rem;
  font-size: 0.7rem;
}

.search{
  font-size: 0.8rem !important;
}

.drop-down-style{
  width: 80px;
  height: 30px;
  font-size: 0.9rem;
}