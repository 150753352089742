.reel-detail-con {
  border: 1px solid $color-btn;
  border-radius: 5px;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 10px;
  overflow: auto;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 75vw;
  max-width: 90vw;
  min-height: 50vh;
  max-height: 90vh;
  overflow: visible;
  h5 {
    text-align: center;
  }
  .main-con {
    min-width: 75vw;
    min-height: 50vh;
    max-height: 80vh;
    overflow: auto;

    table {
      width: 100%;
      margin: 8px 20px;
      thead {
        td {
          text-align: center;
          color: black;
          font-size: 20px;
          font-weight: 400;
        }
      }
      tbody {
        display: table;
        margin-top: 14px;
        th {
          font-size: 12px;
          vertical-align: top;
          width: 20%;
          padding: 10px;
        }
        td {
          font-size: 12px;
          vertical-align: top;
          padding: 10px;
          word-break: break-all;
        }
      }
    }
  }
  .close {
    color: black;
    opacity: 1;
    background-color: white;
    border: 1px solid $color-btn;
    padding: 6px 10px;
    border-radius: 50%;
    font-size: 16px;
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
  }
}
