.gallery-btn {
  padding: 7px 24px;
  background: #3e8993;
  border: 1px solid #3e8993;
  color: #fff !important;
  font-weight: 500;
  border-radius: 5px;
  font-size: 16px;
  margin-right: 5px;
  transition: all 0.3s ease-in-out 0s;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}
.gallery-btn-simple{
  all: unset;
  margin-right: 10px;
  padding: 6px 15px;
  color: #4b4b4b;
  background: linear-gradient(#c9c9c9,#ccc);
  border-radius: 2px;
  transition-duration: 0.3s;
}

.gallery-btn:hover, .gallery-btn-simple:hover {
  opacity: 0.8;
}
.progress-bar {
  background-color: #3e8993 !important;
}
.gallery-btn:disabled {
  opacity: 0.5;
  pointer-events: none;
  background: #9c9c9c;
  border: 1px solid #9c9c9c;
}

.selected-gallery-image {
  border: 4px solid #3e8993 !important;
}

.file-preview {
  display: flex;
  flex-wrap: wrap;
  /*gap: 10px;*/
  background: white;
  padding: 0.5rem;
  padding-bottom: 0;
}

.file-item {
  position: relative;
  /*display: flex;*/
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  /*padding-bottom: 100%; /* Height equal to width */
}

/* Remove height and object-fit from video and img */
.file-item video,
.file-item img {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  width: 100%;
}


.aspect-ratio-container {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%; /* Height equal to width */
}

.file-content {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.checkbox-container {
  position: absolute;
  top: 2px;
  right: 20px;
  z-index: 1;
  cursor: pointer;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  left: 100%;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #3e8993;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
}

.modal-previous,
.modal-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.modal-previous {
  left: 10px;
  color: black;
}

.modal-next {
  border: 1px solid;
  right: -20px;
}

.bg-none{
  background: none;
}

.lock-container {
  position: absolute;
  top: 2px;
  left: 5px;
  z-index: 1;
  color: #3e8993;
}

.play-button-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2; 
  color: white;
}

.css-19kzrtu {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.obj-fit-cover{
  object-fit: cover !important;
}

.rmdp-arrow{
  height: 10px !important;
  width: 10px !important;
}

.image-modal {
  width: 80%; /* Adjust the width as per your preference */
  height: 80%; /* Adjust the height as per your preference */
  max-width: 800px; /* Maximum width of the modal */
  max-height: 600px; /* Maximum height of the modal */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.reason-modal {
  width: 80%; 
  height: 80%; 
  max-width: 800px; 
  max-height: 600px;
  position: absolute;
  top: 20rem;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.8); /* Overlay background color */
}

.modaal-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.prev-btn,
.next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: #3e8993;
  font-size: 24px;
  line-height: 1;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  background-color: #3e8993;
  height: 45px !important;
  width: 35px !important;
  opacity: 0.7;
}

.prev-btn {
  left: 0;
}

.next-btn {
  right: 0;
}

.clr-blue{
  color: #3e8993;
}

.font-size-16{
  font-size: 16px !important;
}

.cursor-pointer{
  cursor: pointer !important;
}

.my-events-sb-gallery-overlay{
  position: fixed;
  z-index: 1002;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.3);
  cursor: progress;
}

.image-download-icon {
  opacity: 0.7;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  align-items: center;
  text-align: center;
  padding: 8px;
  cursor: pointer;
  display: flex;
}

.video-download-icon {
  opacity: 0.7;
  position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    align-items: center;
    text-align: center;
    padding: 8px;
    cursor: pointer;
    display: flex;
}