
.image-container {
    width: 25%; /* Adjust this value to control the number of images per row */
    padding: 0.5rem;
  }
  
  .image-wrapper {
    position: relative;
    overflow: hidden;
    padding-bottom: 100%; /* Maintain a square aspect ratio */
  }
  
  .image-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .stars {
    display: flex;
    align-items: center;
  }
  
  .star {
    font-size: 24px;
    color: #ccc;
    cursor: pointer;
  }
  
  .star.active {
    color: #3e8993;
  }

  .color-custom {
    color: #3e8993;
  }

  .p-color-size{
    color: #000;
    font-size: 16px;
  }

.tbl-holiday {
    overflow: auto !important;
    height: 268px;
    background-color: lightgray;
    margin-top: 27px;
    box-shadow: 0.5px 0.5px 4px 0.5px;
    border-radius: 5px;
}

.rdt_TableHead {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 999;
}
